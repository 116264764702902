import React from 'react';
import styles from './hpi.module.css';
import globalStyles from '../globalStyles.module.css';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  Label,
} from 'recharts';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useState } from 'react';
import { Skeleton } from '@mui/material';
import Overlay from '../Overlay';
import { useEffect } from 'react';
import CustomMarkdown from '../CustomMarkdown';

const HPI = ({ tracts, zips, counties, map }) => {
  // * STATE

  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  // * USE EFFECT
  // none
  useEffect(() => {
    // console.log('Hello from local dev');
  }, []);

  // * FUNCTIONS
  // For converting HPI data from given format to the one needded in line chart
  const convertDataFormat = (zips, tracts, counties) => {
    if (!zips && !tracts && !counties) return null;
    const output = [];

    // ! DO NOT DELETE
    const years = Object.keys(zips || tracts || counties);
    // console.log({ years });
    years.forEach(key => {
      const year = key.split('_')[1];
      const zip = zips && zips[key];
      const tract = tracts && tracts[key];
      const county = counties && counties[key];
      output.push({
        name: year,
        zip: zip || null,
        tract: tract || null,
        county: county || null,
      });
    });
    return output;
  };

  // * For Price Appreciation Since 2011
  // Calculate the % change from the first year to 2011
  const calculateChange = data => {
    if (!data) return null;
    // data is an object
    const parsedData = {};
    Object.entries(data).filter(([key, value]) => {
      if (value === 0) return false;
      const year = key.split('_')[1];
      if (parseInt(year) < 2011) return false;
      return (parsedData[year] = value);
    });

    const years = Object.keys(parsedData).sort((a, b) => b - a);

    const latestYear = years[0];
    const latestHPI = parsedData[latestYear];
    const firstYear = years[years.length - 1];
    const firstHPI = parsedData[firstYear];

    const change = (latestHPI - firstHPI) / firstHPI + 1;
    return { value: change.toFixed(2), since: firstYear };
  };

  // * For Background Rate of Appreciation (1990 - present)
  // Calculate the year over year appreciation
  const calculateAppreciation = data => {
    if (!data) return null;
    const parsedData = {};

    Object.entries(data).filter(([key, value]) => {
      if (value === 0) return false;
      const year = key.split('_')[1];
      return (parsedData[year] = value);
    });

    const years = Object.keys(parsedData).sort((a, b) => b - a);

    const latestYear = years[0];
    const latestHPI = parsedData[latestYear];
    const firstYear = years[years.length - 1];
    const firstHPI = parsedData[firstYear];

    const numberOfYears = latestYear - firstYear;

    // Formula for appreciation
    const appreciation =
      ((latestHPI - firstHPI) / (numberOfYears * firstHPI)) * 100;

    return { value: appreciation.toFixed(2), since: firstYear };
  };
  const tractsPercentages = {
    change: calculateChange(tracts),
    appreciation: calculateAppreciation(tracts),
  };
  const zipsPercentages = {
    change: calculateChange(zips),
    appreciation: calculateAppreciation(zips),
  };
  const countiesPercentages = {
    change: calculateChange(counties),
    appreciation: calculateAppreciation(counties),
  };
  const HPIData = convertDataFormat(zips, tracts, counties);

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  const closeOutsideClick = () => {
    if (isOverlayVisible) {
      setIsOverlayVisible(false);
      handleCloseOverlay();
    }
  };
  const [demoMapLoaded, setDemoMapLoaded] = useState(true);

  const updatedStyles = {
    helpoutlineIconStyle: {
      cursor: 'pointer',
      color: '#4FA490',
      marginTop: '0.5rem',
    },
    iconContainingDiv: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      position: 'relative',
    },
    subsectionHeading: {
      fontFamily: 'Lato',
      fontSize: '22.4px',
      fontWeight: '900',
      lineHeight: '20px',
      color: '#39585C',
      textAlign: 'center',
    },
  };

  return (
    <div className={globalStyles.container} onClick={closeOutsideClick}>
      {demoMapLoaded && (
        <Skeleton
          variant='rectangular'
          sx={{
            height: '35rem',
            width: '100%',
          }}
          animation='wave'
        />
      )}
      <img
        src={map}
        className={globalStyles.mapContainer}
        alt='HPI Map'
        onLoad={() => setDemoMapLoaded(false)}
        style={{ display: demoMapLoaded ? 'none' : 'block' }}
      />
      <div className={styles.sectionContainer}>
        <div className={styles.scale}>
          <h3
            style={{
              ...updatedStyles.subsectionHeading,
            }}
          >
            Price Appreciation Since 2011 (%)
          </h3>
          <div className={styles.numbers}>
            <div>&gt;50</div>
            <div>42.5</div>
            <div>35</div>
            <div>27.5</div>
            <div>&lt;20</div>
          </div>
          <div className={styles.gradient}></div>
        </div>
        <div className={globalStyles.fullRow}>
          <div className={styles.gridder}>
            <div
              className={styles.gridderColumn}
              style={{
                position: 'relative',
              }}
            >
              <h4
                style={{
                  ...updatedStyles.subsectionHeading,
                }}
              >
                TRACT
              </h4>
              <div>
                <h3
                  style={{
                    ...updatedStyles.subsectionHeading,
                    color: 'black',
                    fontWeight: '500',
                    fontSize: '17.6px',
                  }}
                >
                  Price Appreciation Since 2011
                </h3>

                <p
                  style={{
                    ...updatedStyles.subsectionHeading,
                    fontWeight: '900',
                    fontSize: '19.2',
                    color: 'black',
                    marginTop: '10px',
                  }}
                >
                  {isNaN(tractsPercentages?.change?.value) ||
                  !tractsPercentages?.change?.value
                    ? 'Unknown'
                    : tractsPercentages.change.value + 'x'}
                </p>
                <HelpOutlineIcon
                  onClick={() => {
                    handleQuestionMarkClick('PAS-Tract');
                    setIsOverlayVisible(true);
                  }}
                  style={updatedStyles.helpoutlineIconStyle}
                />
                {activeOverlay === 'PAS-Tract' && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '0%',
                      left: '0%',
                      width: '16rem',
                      zIndex: '1000',
                    }}
                  >
                    <Overlay
                      isVisible={isOverlayVisible}
                      onClose={() => {
                        setIsOverlayVisible(false);
                        handleCloseOverlay();
                      }}
                      keyElement={'PAS-Tract'}
                    />
                  </div>
                )}
              </div>
              <div
                style={{
                  position: 'relative',
                }}
              >
                <h3
                  style={{
                    ...updatedStyles.subsectionHeading,
                    color: 'black',
                    fontWeight: '500',
                    fontSize: '17.6px',
                  }}
                >
                  Background Rate of Appreciation (1990 - present)
                </h3>
                <p
                  style={{
                    ...updatedStyles.subsectionHeading,
                    fontWeight: '900',
                    fontSize: '19.2',
                    color: 'black',
                    marginTop: '10px',
                  }}
                >
                  {isNaN(tractsPercentages?.appreciation?.value) ||
                  !tractsPercentages?.appreciation?.value
                    ? 'Unknown'
                    : tractsPercentages.appreciation.value + '% per year'}
                </p>
                <HelpOutlineIcon
                  onClick={() => {
                    handleQuestionMarkClick('BRA-Tract');
                    setIsOverlayVisible(true);
                  }}
                  style={updatedStyles.helpoutlineIconStyle}
                />
                {activeOverlay === 'BRA-Tract' && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '0%',
                      left: '0%',
                      width: '16rem',
                      zIndex: '1000',
                    }}
                  >
                    <Overlay
                      isVisible={isOverlayVisible}
                      onClose={() => {
                        setIsOverlayVisible(false);
                        handleCloseOverlay();
                      }}
                      keyElement={'BRA-Tract'}
                    />
                  </div>
                )}
              </div>
            </div>

            <div
              className={styles.gridderColumn}
              style={{
                position: 'relative',
              }}
            >
              <h4
                style={{
                  ...updatedStyles.subsectionHeading,
                }}
              >
                ZIP
              </h4>
              <div>
                <h3
                  style={{
                    ...updatedStyles.subsectionHeading,
                    color: 'black',
                    fontWeight: '500',
                    fontSize: '17.6px',
                  }}
                >
                  Price Appreciation Since 2011
                </h3>
                <p
                  style={{
                    ...updatedStyles.subsectionHeading,
                    fontWeight: '900',
                    fontSize: '19.2',
                    color: 'black',
                    marginTop: '10px',
                  }}
                >
                  {isNaN(zipsPercentages?.change?.value) ||
                  !zipsPercentages?.change?.value
                    ? 'Unknown'
                    : zipsPercentages.change.value + 'x'}
                </p>
                <HelpOutlineIcon
                  onClick={() => {
                    handleQuestionMarkClick('PAS-Zip');
                    setIsOverlayVisible(true);
                  }}
                  style={updatedStyles.helpoutlineIconStyle}
                />
                {activeOverlay === 'PAS-Zip' && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '0%',
                      left: '0%',
                      width: '16rem',
                      zIndex: '1000',
                    }}
                  >
                    <Overlay
                      isVisible={isOverlayVisible}
                      onClose={() => {
                        setIsOverlayVisible(false);
                        handleCloseOverlay();
                      }}
                      keyElement={'PAS-Zip'}
                    />
                  </div>
                )}
              </div>
              <div
                style={{
                  position: 'relative',
                }}
              >
                <h3
                  style={{
                    ...updatedStyles.subsectionHeading,
                    color: 'black',
                    fontWeight: '500',
                    fontSize: '17.6px',
                  }}
                >
                  Background Rate of Appreciation (1990 - present)
                </h3>
                <p
                  style={{
                    ...updatedStyles.subsectionHeading,
                    fontWeight: '900',
                    fontSize: '19.2',
                    color: 'black',
                    marginTop: '10px',
                  }}
                >
                  {isNaN(zipsPercentages?.appreciation?.value) ||
                  !zipsPercentages?.appreciation?.value
                    ? 'Unknown'
                    : zipsPercentages.appreciation.value + '% per year'}
                </p>
                <HelpOutlineIcon
                  onClick={() => {
                    handleQuestionMarkClick('BRA-Zip');
                    setIsOverlayVisible(true);
                  }}
                  style={updatedStyles.helpoutlineIconStyle}
                />
                {activeOverlay === 'BRA-Zip' && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '0%',
                      left: '0%',
                      width: '16rem',
                      zIndex: '1000',
                    }}
                  >
                    <Overlay
                      isVisible={isOverlayVisible}
                      onClose={() => {
                        setIsOverlayVisible(false);
                        handleCloseOverlay();
                      }}
                      keyElement={'BRA-Zip'}
                    />
                  </div>
                )}
              </div>
            </div>

            <div className={styles.gridderColumn}>
              <h4
                style={{
                  ...updatedStyles.subsectionHeading,
                }}
              >
                COUNTY
              </h4>
              <div
                style={{
                  position: 'relative',
                }}
              >
                <h3
                  style={{
                    ...updatedStyles.subsectionHeading,
                    color: 'black',
                    fontWeight: '500',
                    fontSize: '17.6px',
                  }}
                >
                  Price Appreciation Since 2011
                </h3>
                <p
                  style={{
                    ...updatedStyles.subsectionHeading,
                    fontWeight: '900',
                    fontSize: '19.2',
                    color: 'black',
                    marginTop: '10px',
                  }}
                >
                  {isNaN(countiesPercentages?.change?.value) ||
                  !countiesPercentages?.change?.value
                    ? 'Unknown'
                    : countiesPercentages.change.value + 'x'}
                </p>
                <HelpOutlineIcon
                  onClick={() => {
                    handleQuestionMarkClick('PAS-County');
                    setIsOverlayVisible(true);
                  }}
                  style={updatedStyles.helpoutlineIconStyle}
                />
                {activeOverlay === 'PAS-County' && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '0%',
                      left: '0%',
                      width: '16rem',
                      zIndex: '1000',
                    }}
                  >
                    <Overlay
                      isVisible={isOverlayVisible}
                      onClose={() => {
                        setIsOverlayVisible(false);
                        handleCloseOverlay();
                      }}
                      keyElement={'PAS-County'}
                    />
                  </div>
                )}
              </div>
              <div
                style={{
                  position: 'relative',
                }}
              >
                <h3
                  style={{
                    ...updatedStyles.subsectionHeading,
                    color: 'black',
                    fontWeight: '500',
                    fontSize: '17.6px',
                  }}
                >
                  Background Rate of Appreciation (1990 - present)
                </h3>
                <p
                  style={{
                    ...updatedStyles.subsectionHeading,
                    fontWeight: '900',
                    fontSize: '19.2',
                    color: 'black',
                    marginTop: '10px',
                  }}
                >
                  {isNaN(countiesPercentages?.appreciation?.value) ||
                  !countiesPercentages?.appreciation?.value
                    ? 'Unknown'
                    : countiesPercentages.appreciation.value + '% per year'}
                  {}
                </p>
                <HelpOutlineIcon
                  onClick={() => {
                    handleQuestionMarkClick('BRA-County');
                    setIsOverlayVisible(true);
                  }}
                  style={updatedStyles.helpoutlineIconStyle}
                />
                {activeOverlay === 'BRA-County' && (
                  <div
                    style={{
                      position: 'absolute',
                      top: '0%',
                      left: '0%',
                      width: '16rem',
                      zIndex: '1000',
                    }}
                  >
                    <Overlay
                      isVisible={isOverlayVisible}
                      onClose={() => {
                        setIsOverlayVisible(false);
                        handleCloseOverlay();
                      }}
                      keyElement={'BRA-County'}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        <div className={styles.group3}>
          <h2>HPI TRACT/ZIP INDEX</h2>
          <div className={styles.map}>
            <HPIChart data={HPIData} />
          </div>
        </div>
        <div className={styles.captionText}>
          <p>
            <span>
              {' '}
              The Federal Housing Finance Agency (FHFA) House Price Index (HPI){' '}
            </span>
            is a weighted, repeat-sales index that measures the movement of
            house prices in the United States. The HPI is calculated with the
            average price changes in repeat sales or re-financings on the same
            properties. The FHFA HPI data set is built on tens of millions of
            sales and offers insights about price fluctuations at the national,
            census division, state, metro area, county, ZIP code, and census
            tract levels¹.
          </p>

          <p>
            <span> If an HPI value increased by 10% from 1990 to 1991 </span>,
            it means that house prices increased by 10% during that period. The
            HPI base is 100: all subsequent years are a measurement of total
            appreciation since the base year. For example, an HPI value of 756
            taken in 2023 means that area has appreciated 656% (756-100=656%)
            since 1990 (1990 as the base year has a value of 100).
          </p>

          <p>
            <span>
              {' '}
              The background rate of appreciation is the expected annual
              appreciation{' '}
            </span>
            per year for a given given area. It is calculated by taking the
            total appreciation since the base year divided by the number of
            years.
          </p>
        </div>

        <CustomMarkdown sectionName='hpi' />
      </div>
    </div>
  );
};

const HPIChart = ({ data }) => {
  if (!data) return null;
  const prioriProps = [
    {
      stroke: '#4f7d70',
      dot: { r: 5, fill: 'white', stroke: '#659f8f' },
    },
    {
      stroke: '#b1cec6',
      dot: { r: 0 },
    },
    {
      stroke: '#80ffaa',
      dot: { r: 0 },
    },
  ];
  const assignPrioris = data => {
    const output = [];
    // Check if zip is not all 0
    const zipStatus = data.some(
      item => item['zip'] != 0 && !(item['zip'] == undefined),
    );
    // Check if county is not all 0
    const countyStatus = data.some(
      item => item['county'] != 0 && !(item['county'] == undefined),
    );
    // Check if tract is not all 0
    const tractStatus = data.some(
      item => item['tract'] != 0 && !(item['tract'] == undefined),
    );

    if (tractStatus)
      output.push(prioriProps[0], prioriProps[1], prioriProps[2]);
    else if (zipStatus)
      output.push(prioriProps[1], prioriProps[0], prioriProps[2]);
    else if (countyStatus)
      output.push(prioriProps[2], prioriProps[1], prioriProps[0]);
    else output.push([...prioriProps]);
    return output;
  };
  const [tractProps, zipProps, countyProps] = assignPrioris(data);
  const width = 1000;
  const height = 500;
  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      const value = payload[0]?.payload;
      return (
        <div>
          <p className='label'>{`Year ${label}`}</p>
          {value.tract && <p className='value'>{`Tract: ${value.tract}`}</p>}
          {value.zip && <p className='value'>{`Zip: ${value.zip}`}</p>}
          {value.county && <p className='value'>{`County: ${value.county}`}</p>}
        </div>
      );
    }

    return null;
  };
  const isMobile = window.innerWidth <= 768;

  return (
    <ResponsiveContainer width='100%' height='100%'>
      <LineChart data={data}>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' fontSize={14} />
        {isMobile ? null : (
          <YAxis>
            <Label angle={-90} fontSize={6} position='insideLeft' />
          </YAxis>
        )}
        <Tooltip content={<CustomTooltip />} />
        <Legend
          iconType='rect'
          iconSize={20}
          margin={(0, 0, 0, -10)}
          verticalAlign='bottom'
        />
        <Line
          key={2}
          dataKey='tract'
          name='Tract'
          strokeWidth={3}
          {...tractProps}
        />
        <Line key={1} dataKey='zip' name='Zip' strokeWidth={3} {...zipProps} />
        <Line
          key={3}
          dataKey='county'
          name='County'
          strokeWidth={3}
          {...countyProps}
        />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default HPI;
