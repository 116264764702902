import { React, useEffect, useState } from 'react';
import LaunchIcon from '@mui/icons-material/Launch';
import {
  Grid,
  Button,
  Select,
  MenuItem,
  Snackbar,
  Box,
  Alert,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Container,
  CircularProgress,
  Typography,
} from '@mui/material';
import VestmapLoader from './mongodb/VestmapLoader';
import PlaceComponent from './place.component';
import VestmapViewer from './VestmapViewer';
import { useAuth } from './../AuthProvider';
import { useRef } from 'react';
import ErrorPopUp from './pages/BulkVestmaps/ErrorPopUp';
import Header from './layout/Header';
import { ReactComponent as MarkerIconLight } from '@/../../public/markericonlight.svg';
import { ReactComponent as MarkerIconGreen } from '@/../../public/markericongreen.svg';
import { ReactComponent as DeleteIconRed } from '@/../../public/deleteIconRed.svg';

import OpenInNewIcon from '@mui/icons-material/OpenInNew';

const Content = props => {
  const { getAccessToken, openChangePlan } = useAuth();

  // * STATE
  const [bedroom, setBedroom] = useState('Select Bedrooms');
  const [userCredit, setUserCredit] = useState(0);
  const [currentPlan, setCurrentPlan] = useState(() => {
    if (localStorage) {
      const user = JSON.parse(localStorage.getItem('mongodbUser'));
      return user.current_plan;
    }
  });
  const [loaders, setLoaders] = useState([]);
  const [viewer, setViewer] = useState(false);
  const [vestmaps, setVestmaps] = useState([]);
  const [currentVestmaps, setCurrentVestmaps] = useState([]);
  const [page, setPage] = useState(1);
  const [failState, setFailState] = useState({ state: false, loaderId: '' });
  const [totalCount, setTotalCount] = useState(0);
  const [loadingState, setLoadingState] = useState({ vestmaps: false });
  const [currentVestmapsLoading, setCurrentVestmapsLoading] = useState(true);
  const [limitError, setLimitError] = useState(false);
  const [errorString, setErrorString] = useState('');
  const [successOpen, setSuccessOpen] = useState(false); // For SnackBar
  const [failOpen, setFailOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false); // For Vestmap Viewer Modal
  const [currentVestmap, setCurrentVestmap] = useState({});
  const sseConnectionRef = useRef(false);
  const [loaderID, setLoaderID] = useState('');
  const [bulkSearches, setBulkSearches] = useState({
    remaining: 0,
    total: 0,
  });
  const [proSearches, setProSearches] = useState({
    remaining: 0,
    total: 0,
  });
  const [expirationDate, setExpirationDate] = useState(null);
  const [isSideBarOpen, setIsSideBarOpen] = useState(false);
  const [isVideoLoading, setIsVideoLoading] = useState(true);
  const [isAdmin, setIsAdmin] = useState(false);

  // * USE EFFECTS
  useEffect(() => {
    sseListener(); // for normal events
    syncSSERefresh(); // in case of refresh
  }, []);

  useEffect(() => {
    if (sseConnectionRef.current) {
      computationRefreshSSE();
    }
  }, [vestmaps]);

  useEffect(() => {}, [currentPlan]);

  useEffect(() => {}, [vestmaps]);

  useEffect(() => {
    if (failState.state) {
      let updatedLoaders = [];
      loaders.forEach(loader => {
        if (loader._id === failState.loaderId) {
          loader.status = 'failed';
        }
        updatedLoaders.push(loader);
      });
      setLoaders(updatedLoaders);
      setFailState({ state: false, loaderId: '' });
    }
  }, [failState]);

  useEffect(() => {
    if (localStorage) {
      const user = JSON.parse(localStorage.getItem('mongodbUser'));

      if (!user.current_plan) {
        // update here
        const url = process.env.REACT_APP_NODE_URL + '/update-account';
        fetch(url, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },

          body: JSON.stringify({
            id: props.userId,
            vestmapToken: getAccessToken(),
          }),
        })
          .then(res => {
            if (res.status === 201) return res.json();
          })
          .then(data => {
            if (data) {
              setCurrentPlan(prev => {
                return data?.mongodbUser.current_plan;
              });

              setBulkSearches(prev => {
                return {
                  remaining: data?.mongodbUser.bulk_searches_remaining,
                  total: data?.mongodbUser.bulk_searches_total,
                };
              });

              setProSearches(prev => {
                return {
                  remaining: data?.mongodbUser.pro_searches_remaining,
                  total: data?.mongodbUser.pro_searches_total,
                };
              });

              setExpirationDate(prev => {
                return data?.mongodbUser.expiration_date;
              });

              if (localStorage) {
                const user = JSON.parse(localStorage.getItem('mongodbUser'));
                user.current_plan = data?.mongodbUser.current_plan;
                user.bulk_searches_remaining =
                  data?.mongodbUser.bulk_searches_remaining;
                user.bulk_searches_total =
                  data?.mongodbUser.bulk_searches_total;
                user.pro_searches_remaining =
                  data?.mongodbUser.pro_searches_remaining;
                user.pro_searches_total = data?.mongodbUser.pro_searches_total;
                user.expiration_date = data?.mongodbUser.expiration_date;
                localStorage.setItem('mongodbUser', JSON.stringify(user));
              }
            }
          })
          .catch(error => {
            console.log('User Account Update Failed');
          });
      }
    }

    getLoaders();
    getVestmapsCount();
    if (localStorage) {
      setCurrentPlan(prev => {
        const user = JSON.parse(localStorage.getItem('mongodbUser'));
        const plan = user.current_plan;
        return plan;
      });

      setBulkSearches(prev => {
        const user = JSON.parse(localStorage.getItem('mongodbUser'));
        const bulkSearchesRemaining = user.bulk_searches_remaining || 0;
        const bulkSearchesTotal = user.bulk_searches_total || 0;

        return { remaining: bulkSearchesRemaining, total: bulkSearchesTotal };
      });

      setProSearches(prev => {
        const user = JSON.parse(localStorage.getItem('mongodbUser'));
        const proSearchesRemaining = user.pro_searches_remaining || 0;
        const proSearchesTotal = user.pro_searches_total || 0;

        return { remaining: proSearchesRemaining, total: proSearchesTotal };
      });

      setExpirationDate(prev => {
        const user = JSON.parse(localStorage.getItem('mongodbUser'));
        const expirationDate = user.expiration_date || null;
        return expirationDate;
      });
    }
  }, []);

  useEffect(() => {
    vestmapsSetter();
  }, [page]);

  useEffect(() => {
    if (openModal === false) {
      // since the modal is closed, we need to refresh the user's pro searches credit
      setProSearches(prev => {
        const user = JSON.parse(localStorage.getItem('mongodbUser'));
        const proSearchesRemaining = user.pro_searches_remaining || 0;
        const proSearchesTotal = user.pro_searches_total || 0;

        return { remaining: proSearchesRemaining, total: proSearchesTotal };
      });
    }
  }, [openModal]);

  // * FUNCTIONS

  // * SSE EVENT LISTENER
  async function sseListener() {
    const url = process.env.REACT_APP_NODE_URL + '/webhook/planChangeSSE';
    const eventSource = new EventSource(url);

    eventSource.onmessage = event => {
      try {
        const data = JSON.parse(event.data);

        if (data.type === 0) {
        } else if (data.type === 1) {
          setCurrentPlan(prev => {
            return data.newPlan;
          });

          setBulkSearches(prev => {
            return {
              remaining: data.bulk_searches_remaining,
              total: data.bulk_searches_total,
            };
          });

          setProSearches(prev => {
            return {
              remaining: data.pro_searches_remaining,
              total: data.pro_searches_total,
            };
          });

          setExpirationDate(prev => {
            return data.expiration_date;
          });

          if (localStorage) {
            const user = JSON.parse(localStorage.getItem('mongodbUser'));
            user.current_plan = data.newPlan;
            user.bulk_searches_remaining = data.bulk_searches_remaining;
            user.bulk_searches_total = data.bulk_searches_total;
            user.pro_searches_remaining = data.pro_searches_remaining;
            user.pro_searches_total = data.pro_searches_total;
            user.expiration_date = data.expiration_date;
            localStorage.setItem('mongodbUser', JSON.stringify(user));
          }

          eventSource.close();
        }
      } catch (error) {
        console.log('[SSE ERROR]');
        console.log(error);
      }
    };
  }

  async function computationRefreshSSE() {
    const url =
      process.env.REACT_APP_NODE_URL + '/refresh-sync?userID=' + props.userId;
    const eventSource = new EventSource(url);
    eventSource.onmessage = event => {
      try {
        const data = JSON.parse(event.data);
        console.log(data.type);
        if (data.type === -1) {
          eventSource.close();
        } else if (data.type === 1) {
          const vestmap = data.vestmap;
          console.log('SSE: ', vestmap.loaderID);
          console.log('SSE: ', vestmap);
          deleteLoader(vestmap.loaderID);
          setVestmaps([vestmap, ...vestmaps]);
          setCurrentVestmaps([vestmap, ...currentVestmaps]);
          eventSource.close();
        } else if (data.type === -2) {
          setLoaders(prev =>
            prev.map(el =>
              el._id === data.loaderID ? { ...el, status: 'failed' } : el,
            ),
          );
          setFailOpen(true);
          setFailState({ state: true, loaderId: data.loaderID });

          eventSource.close();
        }
      } catch (error) {
        console.log('[ComputationRefreshSSE ERROR]');
        console.log(error);
      }
    };
  }

  async function syncSSERefresh() {
    const url =
      process.env.REACT_APP_NODE_URL +
      '/webhook/planChangeSyncSSE?currentPlan=' +
      currentPlan +
      '&userID=' +
      props.userId;

    const eventSource = new EventSource(url);
    eventSource.onmessage = event => {
      try {
        const data = JSON.parse(event.data);
        if (data.type === 0) {
        } else if (data.type === 1) {
          setCurrentPlan(prev => {
            return data.newPlan;
          });
          setBulkSearches(prev => {
            return {
              remaining: data.bulk_searches_remaining,
              total: data.bulk_searches_total,
            };
          });

          setProSearches(prev => {
            return {
              remaining: data.pro_searches_remaining,
              total: data.pro_searches_total,
            };
          });

          setExpirationDate(prev => {
            return data.expiration_date;
          });

          if (localStorage) {
            const user = JSON.parse(localStorage.getItem('mongodbUser'));
            user.current_plan = data.newPlan;
            user.bulk_searches_remaining = data.bulk_searches_remaining;
            user.bulk_searches_total = data.bulk_searches_total;
            user.pro_searches_remaining = data.pro_searches_remaining;
            user.pro_searches_total = data.pro_searches_total;
            user.expiration_date = data.expiration_date;
            localStorage.setItem('mongodbUser', JSON.stringify(user));
          }

          eventSource.close();
        }
      } catch (error) {
        console.log('[SyncSSERefresh ERROR]');
        console.log(error);
      }
    };
  }

  // insert items into array at index
  const insertAtIndex = (arr, index, newItems) => {
    if (index > arr.length) {
      // If index is beyond the array's length, fill with nulls
      const fillCount = index - arr.length;
      const filler = new Array(fillCount).fill(null);

      return [...arr, ...filler, ...newItems];
    }

    return [...arr.slice(0, index), ...newItems, ...arr.slice(index)];
  };

  // Adds a new loader into the db and returns its id
  const addLoader = async (new_address, bedroom) => {
    // create a proper url using Query String
    const url = new URL(process.env.REACT_APP_NODE_URL + '/vestmap_loader/add');
    const params = {
      userid: props.userId,
      address: new_address,
      bedroom: bedroom,
    };
    url.search = new URLSearchParams(params).toString();

    console.log('URL: ', url);
    // return;
    const response = await fetch(url);
    const data = await response.json();
    setUserCredit(userCredit - 1);
    return data.insertedId;
  };

  const getVestmaps = async (requiredPage, previousVestmaps) => {
    const pg = requiredPage || page;
    const position = (pg - 1) * 12;
    if (totalCount > 0 && position >= totalCount)
      return console.log('[No More Vestmaps]');
    if (vestmaps[position] != null) return console.log('[Already Loaded]');
    const url =
      process.env.REACT_APP_NODE_URL +
      '/vestmaps_page?userid=' +
      props.userId +
      '&page=' +
      pg;
    try {
      setLoadingState({ ...loadingState, vestmaps: true });
      const response = await fetch(url);
      const parsedRes = await response.json();
      if (!parsedRes.status)
        throw new Error('Something went wrong. Please try again later.');
      const newVestmaps = parsedRes.result;
      const prevs = previousVestmaps || vestmaps;
      const total = insertAtIndex(prevs, position, newVestmaps);
      setVestmaps(prev => {
        sseConnectionRef.current = true;
        return total;
      });

      return total;
    } catch (error) {
      window.alert(error.message);
    } finally {
      setLoadingState({ ...loadingState, vestmaps: false });
    }
  };

  const vestmapsSetter = async () => {
    setCurrentVestmapsLoading(true);
    const skip = (page - 1) * 12;
    const currents = vestmaps.slice(skip, skip + 12);
    const nullChecker = arr => arr.every(el => el == null);
    let newList;
    if (currents.length != 0 && !nullChecker(currents))
      setCurrentVestmaps(currents);
    else {
      newList = await getVestmaps();
      const newCurrents = newList.slice(skip, skip + 12);
      setCurrentVestmaps(newCurrents);
    }
    setCurrentVestmapsLoading(false);
    await getVestmaps(page + 1, newList);
  };

  const getVestmapsCount = async () => {
    const url =
      process.env.REACT_APP_NODE_URL +
      '/vestmap_total_count?userid=' +
      props.userId;
    try {
      const response = await fetch(url);
      const parsedRes = await response.json();
      if (!parsedRes.status)
        throw new Error('Something went wrong. Please try again later.');
      console.log({ parsedRes });
      setTotalCount(parsedRes.total_count);
    } catch (error) {
      window.alert(error.message);
    }
  };

  function closeLimitError() {
    setLimitError(false);
  }

  // whenever a loader is added start working on it
  const computeVestmap = async (new_address, bedroom, newLoader) => {
    if (newLoader.status === 'failed') {
      let updatedLoaders = [];
      loaders.forEach(loader => {
        if (loader._id === newLoader._id) {
          loader.status = 'active';
        }
        updatedLoaders.push(loader);
      });
      setLoaders(updatedLoaders);
    }

    const url = new URL(process.env.REACT_APP_NODE_URL + '/compute-vestmap');
    const params = {
      address: new_address,
      bedroom: bedroom,
      api: 'iggouwBhfAl8HuhoRjx',
      userid: props.userId,
      vestmap_loader: newLoader._id,
    };

    url.search = new URLSearchParams(params).toString();

    try {
      setLoaderID(prev => newLoader._id);
      const response = await fetch(url);
      const data = await response.json();

      if (response.status === 401) {
        setErrorString('Your plan has expired.');
        setLimitError(true);
        throw new Error('Your plan has expired.');
      }

      if (!data.status)
        throw new Error('Something went wrong. Please try again later.');

      // Remove the loader and add the vestmap
      deleteLoader(newLoader._id);
      setVestmaps(prev => {
        return [data.vestmap_json, ...prev];
      });

      setCurrentVestmaps(prev => {
        return [data.vestmap_json, ...prev];
      });

      setSuccessOpen(true);
    } catch (error) {
      // alert("Something went wrong. Please try again later.")
      setFailOpen(true);
      setFailState({ state: true, loaderId: newLoader._id });
    }
  };

  // This method fetches the specified loader from the database
  const getLoader = async loaderId => {
    const url =
      process.env.REACT_APP_NODE_URL + `/vestmap_loader/id?loader=${loaderId}`;
    const response = await fetch(url, { method: 'GET' });
    const data = await response.json();
    return data[0];
  };

  const getLoaders = async () => {
    let MongodbUserID = props.userId;
    if (MongodbUserID) {
      let url =
        process.env.REACT_APP_NODE_URL +
        '/vestmap_loader?userid=' +
        MongodbUserID;
      const response = await fetch(url);
      if (!response.ok) {
        const message = `An error occurred: ${response.statusText}`;
        window.alert(message);
        return;
      }
      const loaders = await response.json();
      console.log({ loaders });
      setLoaders(loaders);
    }
  };

  async function deleteLoader(id) {
    try {
      console.log('Deleting Loader: ', id);

      // Delete from database
      let url =
        process.env.REACT_APP_NODE_URL + `/vestmaps_loader/delete?id=${id}`;
      const res = await fetch(url, { method: 'GET' });
      if (!res.ok) {
        setFailOpen(true);
        return;
      }

      // Remove from state
      const newLoaders = loaders.filter(el => el?._id !== id);
      setLoaders(prev => newLoaders);
    } catch (error) {
      console.log('Error in deleteLoader');
      console.log(error);
    }
  }

  async function deleteVestmap(id) {
    try {
      console.log('Deleting Vestmap: ', id);

      // Delete from database
      const url = process.env.REACT_APP_NODE_URL + `/delete-vestmap/${id}`;
      const response = await fetch(url, { method: 'DELETE' });
      if (!response.ok) {
        setFailOpen(true);
        return;
      }
      const data = await response.json();
      console.log(data);

      // Remove from state
      const newVestmaps = vestmaps.filter(vestmap => vestmap?._id !== id);
      setVestmaps(newVestmaps);
      const newCurrentVestmaps = currentVestmaps.filter(
        vestmap => vestmap?._id !== id,
      );
      setCurrentVestmaps(newCurrentVestmaps);
    } catch (error) {
      console.log(error);
    }
  }

  // Vestmap Create
  const RecordCreate = async () => {
    if (bedroom === 'Select Bedrooms') {
      alert('Must Select Bedrooms');
      return;
    }

    let new_address = document.getElementById('address').value;

    console.log('New Address: ', new_address);

    if (new_address === '') {
      alert('Must Add an address');
      return;
    }
    document.getElementById('address').value = '';

    const loaderId = await addLoader(new_address, bedroom);

    // return;
    const newLoader = await getLoader(loaderId);
    setLoaders([newLoader, ...loaders]);
    await computeVestmap(new_address, bedroom, newLoader);
  };

  useEffect(() => {
    console.log({ loaders });
  }, [loaders]);

  const modifyVestmap = newVestmap => {
    try {
      console.log('Modifying Vestmap: ', newVestmap);
      let newVestmaps = [];

      setVestmaps(prev => prev.filter(el => el != null));

      vestmaps.forEach(vestmap => {
        if (vestmap._id === newVestmap._id) {
          newVestmaps.push(newVestmap);
        } else {
          newVestmaps.push(vestmap);
        }
      });
      setVestmaps(newVestmaps);

      let newCurrentVestmaps = [];
      currentVestmaps.forEach(vestmap => {
        if (vestmap._id === newVestmap._id) {
          newCurrentVestmaps.push(newVestmap);
        } else {
          newCurrentVestmaps.push(vestmap);
        }
      });
      setCurrentVestmaps(newCurrentVestmaps);
      console.log('Vestmap Modified');
    } catch (error) {
      console.log('Error in modifyVestmap');
      console.log(error);
    }
  };

  const [pdfGenError, setPdfGenError] = useState(false);
  const [proGenError, setProGenError] = useState(false);

  const openSnackbar = status => {
    if (status === 'success') {
      setSuccessOpen(true);
    } else if (status === 'pdf') {
      setPdfGenError(true);
    } else if (status === 'pro') {
      setProGenError(true);
    } else {
      setFailOpen(true);
    }
  };

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSuccessOpen(false);
    setFailOpen(false);
    setPdfGenError(false);
    setProGenError(false);
  };

  const handleModalOpen = record => {
    setCurrentVestmap(prev => {
      return record;
    });
    setOpenModal(true);
    setViewer(true);
  };

  const closeViewer = state => {
    setViewer(state);
  };

  function setSideBarState(state) {
    setIsSideBarOpen(state);
  }

  return (
    <Box>
      {/* Header */}
      <Box
        sx={{
          paddingX: {
            md: '1.25rem',
            xs: '0',
          },
        }}
      >
        {viewer ? null : (
          <Header sideBarState={setSideBarState} pageTitle={'Run Reports'} />
        )}
      </Box>
      <Box
        sx={{
          flexShrink: 0,
          paddingY: '1rem',
          paddingX: '1.25rem',
          transition: 'all 0.3s ease',
          transform:
            isSideBarOpen == true ? 'translateX(60%)' : 'translateX(0)',
        }}
      >
        <Container
          sx={{
            display: {
              md: 'none',
              xs: 'flex',
            },
            paddingBottom: '1rem',
          }}
        >
          <Typography
            sx={{
              color: '#39585C',
              fontFamily: 'Lato',
              fontSize: '1.5rem',
              fontStyle: 'normal',
              fontWeight: '800',
              lineHeight: '130%',
            }}
          >
            Run Report
          </Typography>
        </Container>

        <Box
          sx={{
            paddingX: '1.2%',
          }}
        >
          {limitError && (
            <ErrorPopUp closeLimitError={closeLimitError} error={errorString} />
          )}
          <Snackbar
            open={successOpen}
            autoHideDuration={1000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity='success'
              sx={{ width: '100%' }}
            >
              Success!
            </Alert>
          </Snackbar>
          <Snackbar
            open={failOpen}
            autoHideDuration={1000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity='error'
              sx={{ width: '100%' }}
            >
              Sorry an error occured. Please try again later.
            </Alert>
          </Snackbar>

          <Snackbar
            open={pdfGenError}
            autoHideDuration={1000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity='error'
              sx={{ width: '100%' }}
            >
              Sorry an error occured while generating the PDF. Please try again
            </Alert>
          </Snackbar>

          <Snackbar
            open={proGenError}
            autoHideDuration={1000}
            onClose={handleClose}
          >
            <Alert
              onClose={handleClose}
              severity='error'
              sx={{ width: '100%' }}
            >
              Sorry an error occured while generating the Pro Report. Please try
            </Alert>
          </Snackbar>

          <Box
            sx={{
              display: 'flex',
              gap: '1rem',
              justifyContent: 'space-between',
              flexWrap: 'wrap',
              flexWrap: {
                xs: 'wrap',
                sm: 'nowrap',
              },
            }}
          >
            <Grid
              md={5}
              sx={{
                padding: '1rem',
                borderRadius: '1.25rem',
                flexGrow: 1,
                border: '1px solid #EDF2F7',
              }}
            >
              {/* Search Bar  */}
              <Grid
                container
                spacing={0}
                display={'flex'}
                sx={{
                  flexDirection: {
                    xs: 'column',
                    sm: 'row',
                  },

                  gap: {
                    sm: '0',
                    xs: '1rem',
                  },
                }}
              >
                <Grid xs={12} sm={8}>
                  <PlaceComponent />
                </Grid>
                <Grid
                  xs={12}
                  sm={4}
                  sx={{
                    paddingX: '1%',
                  }}
                >
                  <Select
                    value={bedroom}
                    onChange={e => setBedroom(e.target.value)}
                    sx={{
                      width: '100%',
                      borderRadius: '1rem',
                      fontWeight: '800',
                      height: '100%',
                      fontSize: '0.875rem',
                      '@media (min-width: 600px) and (max-width: 900px)': {
                        fontSize: '0.70rem',
                      },
                      '@media (min-width: 900px) and (max-width: 1200px)': {
                        fontSize: '0.60rem',
                      },
                    }}
                    labelId='bedrooms'
                    placeholder='Select Bedrooms'
                  >
                    <MenuItem value='Select Bedrooms'>Select Bedrooms</MenuItem>
                    <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
                    <MenuItem value={3}>3</MenuItem>
                    <MenuItem value={4}>4</MenuItem>
                  </Select>
                </Grid>
              </Grid>

              {/* Button */}
              <Grid
                container
                spacing={0}
                sx={{
                  marginTop: '1rem',
                }}
              >
                <Button
                  sx={{
                    width: '100%',
                    borderRadius: '0.75rem',
                    '&:hover': {
                      bgcolor: '#39585C',
                      color: '#FFF',
                      border: '1px solid #4FA490',
                    },
                    '& svg': {
                      height: '1.25rem',
                      width: '1.25rem',
                    },
                    border: '1px solid #FFFFFF',
                    fontSize: '1rem',
                    bgcolor: '#4FA490',
                    color: '#FFFFFF',
                    padding: '2%',
                    textTransform: 'capitalize',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: '0.5rem',
                    fontFamily: "'Lato', sans-serif",
                    position: 'relative', // Make the button a positioned element
                  }}
                  onClick={RecordCreate}
                >
                  <MarkerIconLight />
                  <span>VESTMAP IT</span>
                </Button>
              </Grid>
            </Grid>

            {/* Right Half */}
            <Grid
              container
              md={5}
              sx={{
                padding: '1rem',
                flexGrow: 1,
                borderRadius: '1.25rem',
                border: '1px solid #EDF2F7',
                display: {
                  sm: 'none',
                  md: 'grid',
                },
              }}
            >
              <Grid
                sx={{
                  fontFamily: 'Lato',
                  fontSize: '1.125rem',
                  fontStyle: 'normal',
                  fontWeight: '800',
                  lineHeight: '135%',
                  letterSpacing: '0.0125rem',
                  color: '#1A202C',
                }}
              >
                Searches Remaining
              </Grid>
              <Box
                sx={{
                  padding: '1rem',
                  paddingX: {
                    md: '0',
                    xs: '0',
                  },

                  display: 'flex',
                  width: '100%',
                  gap: '1rem',
                }}
              >
                <Grid
                  md={7}
                  xs={6}
                  sx={{
                    borderRadius: '1.25rem',
                    padding: '1rem',
                    textAlign: 'center',
                    border: '1px solid #EDF2F7',
                    display: 'flex',
                    fontSize: {
                      xs: '0.8rem',
                      sm: '1rem',
                    },
                    flexDirection: 'column',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: {
                        xs: 'column',
                        sm: 'row',
                      },
                      justifyContent: 'space-between',
                    }}
                  >
                    <Box>Pro Searches:</Box>
                    <Box
                      sx={{
                        fontFamily: 'Lato',

                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '150%',
                        letterSpacing: '0.0125rem',
                        color: '#1A202C',
                      }}
                    >
                      {proSearches.remaining} / {proSearches.total}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      flexDirection: {
                        xs: 'column',
                        sm: 'row',
                      },
                    }}
                  >
                    <Box>Bulk Searches:</Box>
                    <Box
                      sx={{
                        fontFamily: 'Lato',

                        fontStyle: 'normal',
                        fontWeight: '400',
                        lineHeight: '150%',
                        letterSpacing: '0.0125rem',
                        color: '#1A202C',
                      }}
                    >
                      {bulkSearches.remaining} / {bulkSearches.total}
                    </Box>
                  </Box>
                </Grid>
                <Grid
                  xs={6}
                  md={5}
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <Button
                    sx={{
                      width: '100%',
                      borderRadius: '12px',
                      '&:hover': {
                        bgcolor: '#39585C',
                        color: '#FFF',
                        border: '1px solid #4FA490',
                      },
                      bgcolor: '#4FA490',
                      color: '#FFFFFF',
                      padding: '10px',
                      textTransform: 'capitalize',
                      fontWeight: '700',
                      fontSize: {
                        xs: '0.8rem',
                        sm: '0.9rem',
                      },

                      display: 'flex',
                      justifyContent: 'center',
                      gap: '2px',
                      fontFamily: '"Lato",sans-serif',
                    }}
                    startIcon={<OpenInNewIcon />}
                    onClick={openChangePlan}
                  >
                    <span>Get More Searches</span>
                  </Button>
                </Grid>
              </Box>
            </Grid>
          </Box>
          <Box
            sx={{
              borderRadius: '1rem',
              border: '1px solid #EDF2F7',
              marginTop: '2rem',
            }}
          >
            <Grid>
              <Typography
                sx={{
                  fontFamily: 'Lato',
                  fontSize: '1.5rem',
                  fontStyle: 'normal',
                  fontWeight: '800',
                  lineHeight: '135%',
                  letterSpacing: '0.0125rem',
                  padding: '1rem',
                  color: '#1A202C',
                }}
              >
                My VestMaps
              </Typography>
            </Grid>
            <Grid
              container
              sx={{
                marginTop: '1rem',
                paddingX: '1rem',
              }}
            >
              {/* Render vestmaps if not loading or loading complete*/}
              {!currentVestmapsLoading &&
                (currentVestmaps.length > 0 || loaders.length !== 0) && (
                  <div className='divvy-div'>
                    {[...loaders, ...currentVestmaps].map(item => {
                      if (
                        item?.status === 'active' ||
                        item?.status === 'failed'
                      ) {
                        // If it's a loader
                        return (
                          <VestmapLoader
                            loader={item}
                            computeVestmap={computeVestmap}
                            deleteLoader={deleteLoader}
                            key={item._id}
                          />
                        );
                      } else {
                        // If it's a vestmap
                        return (
                          <VestmapBox
                            record={item || null}
                            handleModalOpen={handleModalOpen}
                            id={item?._id}
                            key={item?._id}
                            handleDeleteVestmap={deleteVestmap}
                          />
                        );
                      }
                    })}
                  </div>
                )}

              {/* Render loaders when initial loading */}
              {currentVestmapsLoading && (
                <div className='divvy-div'>
                  {Array.from({ length: 12 }, (_, i) => (
                    <VestmapLoader loader={{ status: 'active' }} />
                  ))}
                </div>
              )}

              {!currentVestmapsLoading &&
                currentVestmaps.length === 0 &&
                loaders.length === 0 && (
                  <Box
                    sx={{
                      paddingLeft: '7%',
                      height: '20rem',
                      width: '70%',
                      position: 'relative',
                    }}
                  >
                    {isVideoLoading && (
                      <CircularProgress
                        sx={{
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          transform: 'translate(-50%, -50%)',
                          color: '#39585C',
                        }}
                      />
                    )}
                    <iframe
                      src='https://www.loom.com/embed/25e783d1638d48e09d5b562a414185a8?sid=82f592bc-ac94-459a-9a99-ed8b2eea892e'
                      frameBorder='0'
                      webkitAllowFullScreen
                      mozAllowFullScreen
                      allowFullScreen
                      onLoad={() => setIsVideoLoading(false)}
                      style={{
                        height: '100%',
                        width: '100%',
                        display: isVideoLoading ? 'none' : 'block',
                      }}
                    ></iframe>
                  </Box>
                )}
            </Grid>
            <Grid marginY={'1rem'}>
              <ul className='pagination'>
                {Array.from({ length: totalCount / 12 }, (_, i) => (
                  <li key={i}>
                    <button
                      onClick={() => !loadingState.vestmap && setPage(i + 1)}
                      style={{
                        backgroundColor: page === i + 1 ? '#39585c' : '#fff',
                        color: page === i + 1 ? '#fff' : '#000',
                        cursor: 'pointer',
                      }}
                    >
                      {i + 1}
                    </button>
                  </li>
                ))}
              </ul>
            </Grid>
          </Box>
        </Box>
        {viewer && (
          <VestmapViewer
            open={openModal}
            setOpen={setOpenModal}
            viewerState={closeViewer}
            vestmapData={currentVestmap}
            openSnackbar={openSnackbar}
            modifyVestmap={modifyVestmap}
          />
        )}
      </Box>
    </Box>
  );
};

const VestmapBox = props => {
  const [loader, setLoader] = useState(false);
  const [deleteConfirmation, setDeleteConfirmation] = useState(false);
  const [deletionID, setDeletionID] = useState(null);

  async function deleteVestmap(id) {
    try {
      setLoader(true);
      await props.handleDeleteVestmap(id);
      setLoader(false);
    } catch (error) {
      console.log(error);
      setLoader(false);
    }
  }

  return (
    <Box
      key={props.id}
      sx={{
        alignSelf: 'center',
        '@media (max-width: 600px)': {
          width: '100%',
        },
        '@media only screen and (min-width: 600px) and (max-width: 1023px)': {
          width: '49%',
        },
        '@media only screen and (min-width: 1024px)': {
          width: '32%',
        },
        borderRadius: '1.21144rem',
        overflow: 'hidden',
        display: 'flex',
        flexDirection: 'column',
        marginY: '1rem',
        boxShadow: '5px 20px 30px 0px rgba(229, 229, 229,0.7)',
      }}
    >
      {/*   PopUp asking for delete confirmation confirmation */}
      <Dialog
        open={deleteConfirmation}
        onClose={() => {
          setDeletionID(null);
          setDeleteConfirmation(false);
        }}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        PaperProps={{
          sx: {
            borderRadius: '1.25rem',
          },
        }}
      >
        <DialogTitle id='alert-dialog-title'>{'Remove Report'}</DialogTitle>
        <DialogContent>
          <DialogContentText id='alert-dialog-description'>
            Are you sure you want to remove this report?{' '}
          </DialogContentText>
        </DialogContent>

        <DialogActions
          sx={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Button
            onClick={() => {
              setDeletionID(null);
              setDeleteConfirmation(false);
            }}
            sx={{
              color: '#39585C',
              border: '1px solid #39585C',
              borderRadius: '0.75rem',
              height: '2.5rem',
            }}
          >
            No
          </Button>
          <Button
            onClick={async () => {
              console.log('Deleting: ', deletionID);
              deleteVestmap(deletionID);
              setDeleteConfirmation(false);
              setDeletionID(null);
            }}
            color='error'
            sx={{
              border: '1px solid red',
              borderRadius: '0.75rem',
              height: '2.5rem',
              ':hover': {
                bgcolor: 'red',
                color: 'white',
              },
            }}
          >
            {loader ? <CircularProgress size={15} color='error' /> : 'Yes'}
          </Button>
        </DialogActions>
      </Dialog>

      <Box
        sx={{
          width: '100%',
          height: '150px',
          overflow: 'hidden',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <img
          src={
            props?.record?.assets?.maps?.google_map ||
            `https://oldapp.vestmap.com/google_img/${props?.record?.google_image_url}`
          }
          onError={({ currentTarget }) => {
            currentTarget.onerror = null;
            currentTarget.src = require('../img/defaultMap.png');
          }}
          alt='Vestmap Default Map'
          style={{
            width: '100%',
            height: 'auto',
            objectFit: 'cover',
          }}
        />
      </Box>
      <Box
        sx={{
          width: '100%',
          padding: '1.25rem 0',
          textAlign: 'center',
        }}
      >
        <Typography
          variant='h6'
          component='h3'
          textAlign={'center'}
          sx={{
            fontFamily: '"Lato", sans-serif',
            paddingX: '1rem',
            fontSize: '1.25rem',
            fontStyle: 'normal',
            fontWeight: '700',
            lineHeight: '140%',
            color: '#323142',
          }}
        >
          {props?.record?.address_info.address}
        </Typography>
      </Box>
      <Box
        sx={{
          width: '100%',
          textAlign: 'center',
          marginY: '1.5rem',
          display: 'flex',
          justifyContent: 'center',
          gap: '16px',
        }}
      >
        <Button
          target='_blank'
          startIcon={<LaunchIcon />}
          variant='outlined'
          size='large'
          onClick={() => props.handleModalOpen(props.record)}
          sx={{
            border: '2px solid #39585C',
            borderRadius: '0.75rem',
            color: 'black',
            textTransform: 'capitalize',
            fontFamily: '"Lato", sans-serif',
            fontSize: '0.875rem',
            fontStyle: 'normal',
            fontWeight: '600',
            lineHeight: '150%',
            ':hover': {
              border: '2px solid #39585C',
              bgcolor: '#39585C',
              color: 'white',
            },
          }}
        >
          View Report
        </Button>
        <Box
          sx={{
            width: '52px',
            height: '3rem',
            border: '2px solid #D32F2F',
            borderRadius: '12px',

            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            cursor: 'pointer',
          }}
          onClick={() => {
            if (loader) return;

            setDeletionID(props.record._id);
            setDeleteConfirmation(true);
          }}
        >
          {loader ? (
            <CircularProgress size={20} color='error' />
          ) : (
            <DeleteIconRed />
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default Content;
