import React from 'react';
import styles from './income.module.css';
import { Skeleton, Box } from '@mui/material';
import globalStyles from '../globalStyles.module.css';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useState } from 'react';
import Overlay from '../Overlay';
import ColoredSquare from './ColoredSquare';
import CustomMarkdown from '../CustomMarkdown';

const Income = ({
  income,
  address_info,
  IncomeStats1,
  IncomeStats2,
  IncomeStats3,
  IncomeStats4,
  IncomeStats5,
  IncomeStats6,
  map,
}) => {
  const [demoMapLoaded, setDemoMapLoaded] = useState(true);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);
  const groupStyle = {
    display: 'flex',
    flexDirection: 'row',
    height: '30%',
    alignItems: 'flex-end',
  };
  const columnStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '5rem',
    width: '20%',
  };

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const handleCloseOverlay = () => {
    setActiveOverlay(null);
  };

  const closeOutsideClick = () => {
    if (isOverlayVisible) {
      setIsOverlayVisible(false);
      handleCloseOverlay();
    }
  };

  const updatedStyles = {
    helpoutlineIconStyle: {
      cursor: 'pointer',
      color: '#4FA490',
      marginTop: '1rem',
    },
    iconContainingDiv: {
      display: 'flex',
      alignItems: 'center',
      gap: '0.5rem',
      position: 'relative',
    },
    subsectionHeading: {
      fontFamily: 'Lato',
      fontSize: '24px',
      fontWeight: '900',
      lineHeight: '20px',
      color: '#39585C',
      textAlign: 'center',
    },
    responsiveMediaQuery: {
      '@media (max-width: 700px)': {
        fontSize: '10px',
        height: '100%',
      },
      '@media (min-width: 700px)': {
        fontSize: '12px',
        height: '100%',
      },
      '@media (min-width: 1024px)': {
        fontSize: '14px',
        height: '100%',
      },
      '@media (min-width: 1320px)': {
        fontSize: '18px',
        height: '100%',
      },
      '@media (min-width: 1424px)': {
        fontSize: '20px',
        height: '100%',
      },
    },
  };

  return (
    <div className={globalStyles.container} onClick={closeOutsideClick}>
      {demoMapLoaded && (
        <Skeleton
          variant='rectangular'
          sx={{
            height: '35rem',
            width: '100%',
          }}
          animation='wave'
        />
      )}
      <img
        src={map}
        className={globalStyles.mapContainer}
        alt='Income Map'
        onLoad={() => setDemoMapLoaded(false)}
        style={{ display: demoMapLoaded ? 'none' : 'block' }}
      />
      <div className={styles.sectionContainer}>
        <div className={`${globalStyles.fullRow} ${globalStyles.stats}`}>
          <Box
            sx={{
              '@media (max-width: 1320px)': {
                width: '100%',
              },
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'column',
              }}
            >
              <h2
                style={{
                  ...updatedStyles.subsectionHeading,
                  fontWeight: '700',
                }}
              >
                2023 Median Household Income
              </h2>
              <h3
                style={{
                  ...updatedStyles.subsectionHeading,
                  fontWeight: '500',
                  fontSize: '16px',
                  color: '#4FA490',
                }}
              >
                Block Group
              </h3>
            </Box>
          </Box>
          <Box
            sx={[
              columnStyle,
              {
                '@media (max-width: 600px)': {
                  width: '34%',
                },
                '@media (min-width: 600px)': {
                  width: '34%',
                },
                '@media (min-width: 900px)': {
                  width: '30%',
                },
                '@media (min-width: 1320px)': {
                  width: '20%',
                },
              },
            ]}
          >
            <Box sx={[groupStyle, updatedStyles.responsiveMediaQuery]}>
              <ColoredSquare color={'26, 152, 80, 1'} />
              {'> 100,000'}
            </Box>
            <Box sx={[groupStyle, updatedStyles.responsiveMediaQuery]}>
              <ColoredSquare color={'102, 189, 99, 1'} /> {'> 90,000 - 100,000'}
            </Box>
            <Box sx={[groupStyle, updatedStyles.responsiveMediaQuery]}>
              <ColoredSquare color={'166, 217, 106, 1'} />
              {'> 75,000 - 90,000'}
            </Box>
          </Box>
          <Box
            sx={[
              columnStyle,
              {
                '@media (max-width: 600px)': {
                  width: '30%',
                },
                '@media (min-width: 600px)': {
                  width: '30%',
                },
                '@media (min-width: 900px)': {
                  width: '30%',
                },
                '@media (min-width: 1320px)': {
                  width: '20%',
                },
              },
            ]}
          >
            <Box sx={[groupStyle, updatedStyles.responsiveMediaQuery]}>
              <ColoredSquare color={'217, 239, 139, 1'} /> {'> 60,000 - 75,000'}
            </Box>
            <Box sx={[groupStyle, updatedStyles.responsiveMediaQuery]}>
              <ColoredSquare color={'255, 255, 191, 1'} /> {'> 50,000 - 60,000'}
            </Box>
            <Box sx={[groupStyle, updatedStyles.responsiveMediaQuery]}>
              <ColoredSquare color={'254, 224, 139, 1'} /> {'> 40,000 - 50,000'}
            </Box>
          </Box>
          <Box
            sx={[
              columnStyle,
              {
                '@media (max-width: 600px)': {
                  width: '30%',
                },
                '@media (min-width: 600px)': {
                  width: '30%',
                },
                '@media (min-width: 900px)': {
                  width: '30%',
                },
                '@media (min-width: 1320px)': {
                  width: '20%',
                },
              },
            ]}
          >
            <Box sx={[groupStyle, updatedStyles.responsiveMediaQuery]}>
              <ColoredSquare color={'253, 174, 97, 1'} /> {'> 30,000 - 40,000'}
            </Box>
            <Box sx={[groupStyle, updatedStyles.responsiveMediaQuery]}>
              <ColoredSquare color={'244, 109, 67, 1'} />
              {'> 15,000 - 30,000'}
            </Box>
            <Box sx={[groupStyle, updatedStyles.responsiveMediaQuery]}>
              <ColoredSquare color={'215, 48, 39, 1'} />
              {'> 0 - 15,000'}
            </Box>
          </Box>
        </div>
        <h2
          style={{
            ...updatedStyles.subsectionHeading,
            fontWeight: '700',
            textAlign: 'center',
            margin: '1rem auto',
          }}
        >
          Median Household Income
        </h2>
        <div className={`${styles.section1}`}>
          <div
            className={`${globalStyles.grayBox}`}
            style={{
              position: 'relative',
            }}
          >
            <h4>Block</h4>
            <h3>
              {'$' +
                parseInt(
                  income?.median_household_income_block1,
                )?.toLocaleString('en-US') || 'N/A'}
            </h3>
            <HelpOutlineIcon
              onClick={() => {
                handleQuestionMarkClick('Block');
                setIsOverlayVisible(true);
              }}
              style={updatedStyles.helpoutlineIconStyle}
            />
            {activeOverlay === 'Block' && (
              <div
                style={{
                  position: 'absolute',
                  top: '0%',
                  left: '0%',
                  width: '16rem',
                  zIndex: '1000',
                }}
              >
                <Overlay
                  isVisible={isOverlayVisible}
                  onClose={() => {
                    setIsOverlayVisible(false);
                    handleCloseOverlay();
                  }}
                  keyElement={'Block'}
                />
              </div>
            )}
          </div>
          <div
            className={`${globalStyles.grayBox}`}
            style={{
              position: 'relative',
            }}
          >
            <h4>Tract</h4>
            <h3>
              {'$' +
                income?.median_household_income_tract1?.toLocaleString(
                  'en-US',
                ) || 'N/A'}
            </h3>
            <HelpOutlineIcon
              onClick={() => {
                handleQuestionMarkClick('Tract');
                setIsOverlayVisible(true);
              }}
              style={updatedStyles.helpoutlineIconStyle}
            />
            {activeOverlay === 'Tract' && (
              <div
                style={{
                  position: 'absolute',
                  top: '0%',
                  left: '0%',
                  width: '16rem',
                  zIndex: '1000',
                }}
              >
                <Overlay
                  isVisible={isOverlayVisible}
                  onClose={() => {
                    setIsOverlayVisible(false);
                    handleCloseOverlay();
                  }}
                  keyElement={'Tract'}
                />
              </div>
            )}
          </div>
          <div
            className={`${globalStyles.grayBox}`}
            style={{
              position: 'relative',
            }}
          >
            <h4>Zip</h4>
            <h3>
              {'$' +
                income?.median_household_income_zip1?.toLocaleString('en-US') ||
                'N/A'}
            </h3>
            <HelpOutlineIcon
              onClick={() => {
                handleQuestionMarkClick('Zip');
                setIsOverlayVisible(true);
              }}
              style={updatedStyles.helpoutlineIconStyle}
            />
            {activeOverlay === 'Zip' && (
              <div
                style={{
                  position: 'absolute',
                  top: '0%',
                  left: '0%',
                  width: '16rem',
                  zIndex: '1000',
                }}
              >
                <Overlay
                  isVisible={isOverlayVisible}
                  onClose={() => {
                    setIsOverlayVisible(false);
                    handleCloseOverlay();
                  }}
                  keyElement={'Zip'}
                />
              </div>
            )}
          </div>
          <div
            className={`${globalStyles.grayBox}`}
            style={{
              position: 'relative',
            }}
          >
            <h4>County</h4>
            <h3>
              {'$' +
                income?.median_household_income_county?.toLocaleString(
                  'en-US',
                ) || 'N/A'}
            </h3>
            <HelpOutlineIcon
              onClick={() => {
                handleQuestionMarkClick('County');
                setIsOverlayVisible(true);
              }}
              style={updatedStyles.helpoutlineIconStyle}
            />
            {activeOverlay === 'County' && (
              <div
                style={{
                  position: 'absolute',
                  top: '0%',
                  left: '0%',
                  width: '16rem',
                  zIndex: '1000',
                }}
              >
                <Overlay
                  isVisible={isOverlayVisible}
                  onClose={() => {
                    setIsOverlayVisible(false);
                    handleCloseOverlay();
                  }}
                  keyElement={'County'}
                />
              </div>
            )}
          </div>
          <div
            className={`${globalStyles.grayBox}`}
            style={{
              position: 'relative',
            }}
          >
            <h4>State</h4>
            <h3>
              {'$' +
                income?.median_household_income_state?.toLocaleString(
                  'en-US',
                ) || 'N/A'}
            </h3>
            <HelpOutlineIcon
              onClick={() => {
                handleQuestionMarkClick('State');
                setIsOverlayVisible(true);
              }}
              style={updatedStyles.helpoutlineIconStyle}
            />
            {activeOverlay === 'State' && (
              <div
                style={{
                  position: 'absolute',
                  top: '0%',
                  left: '0%',
                  width: '16rem',
                  zIndex: '1000',
                }}
              >
                <Overlay
                  isVisible={isOverlayVisible}
                  onClose={() => {
                    setIsOverlayVisible(false);
                    handleCloseOverlay();
                  }}
                  keyElement={'State'}
                />
              </div>
            )}
          </div>
          <div
            className={`${globalStyles.grayBox}`}
            style={{
              position: 'relative',
            }}
          >
            <h4>National</h4>
            <h3>
              {'$' +
                income?.median_household_income_national?.toLocaleString(
                  'en-US',
                ) || 'N/A'}
            </h3>
            <HelpOutlineIcon
              onClick={() => {
                handleQuestionMarkClick('National');
                setIsOverlayVisible(true);
              }}
              style={updatedStyles.helpoutlineIconStyle}
            />
            {activeOverlay === 'National' && (
              <div
                style={{
                  position: 'absolute',
                  top: '0%',
                  left: '0%',
                  width: '16rem',
                  zIndex: '1000',
                }}
              >
                <Overlay
                  isVisible={isOverlayVisible}
                  onClose={() => {
                    setIsOverlayVisible(false);
                    handleCloseOverlay();
                  }}
                  keyElement={'National'}
                />
              </div>
            )}
          </div>
        </div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <h2
            style={{
              ...updatedStyles.subsectionHeading,
              fontWeight: '700',
              textAlign: 'center',
              margin: '2rem auto',
            }}
          >
            Median Home Value
          </h2>
          <div className={styles.section1}>
            <div
              className={`${globalStyles.grayBox}`}
              style={{
                position: 'relative',
              }}
            >
              <h4>Block</h4>
              <h3>
                {'$' +
                  income?.median_home_value_block?.toLocaleString('en-US') ||
                  'N/A'}
              </h3>
              <HelpOutlineIcon
                onClick={() => {
                  handleQuestionMarkClick('MHVB');
                  setIsOverlayVisible(true);
                }}
                style={updatedStyles.helpoutlineIconStyle}
              />
              {activeOverlay === 'MHVB' && (
                <div
                  style={{
                    position: 'absolute',
                    top: '0%',
                    left: '0%',
                    width: '16rem',
                    zIndex: '1000',
                  }}
                >
                  <Overlay
                    isVisible={isOverlayVisible}
                    onClose={() => {
                      setIsOverlayVisible(false);
                      handleCloseOverlay();
                    }}
                    keyElement={'MHVB'}
                  />
                </div>
              )}
            </div>
            <div
              className={`${globalStyles.grayBox}`}
              style={{
                position: 'relative',
              }}
            >
              <h4>Tract</h4>
              <h3>
                {'$' +
                  income?.median_home_value_tract?.toLocaleString('en-US') ||
                  'N/A'}
              </h3>
              <HelpOutlineIcon
                onClick={() => {
                  handleQuestionMarkClick('MHVT');
                  setIsOverlayVisible(true);
                }}
                style={updatedStyles.helpoutlineIconStyle}
              />
              {activeOverlay === 'MHVT' && (
                <div
                  style={{
                    position: 'absolute',
                    top: '0%',
                    left: '0%',
                    width: '16rem',
                    zIndex: '1000',
                  }}
                >
                  <Overlay
                    isVisible={isOverlayVisible}
                    onClose={() => {
                      setIsOverlayVisible(false);
                      handleCloseOverlay();
                    }}
                    keyElement={'MHVT'}
                  />
                </div>
              )}
            </div>
            <div
              className={`${globalStyles.grayBox}`}
              style={{
                position: 'relative',
              }}
            >
              <h4>Zip</h4>
              <h3>
                {'$' + income?.median_home_value_zip?.toLocaleString('en-US') ||
                  'N/A'}
              </h3>
              <HelpOutlineIcon
                onClick={() => {
                  handleQuestionMarkClick('MHVZ');
                  setIsOverlayVisible(true);
                }}
                style={updatedStyles.helpoutlineIconStyle}
              />
              {activeOverlay === 'MHVZ' && (
                <div
                  style={{
                    position: 'absolute',
                    top: '0%',
                    left: '0%',
                    width: '16rem',
                    zIndex: '1000',
                  }}
                >
                  <Overlay
                    isVisible={isOverlayVisible}
                    onClose={() => {
                      setIsOverlayVisible(false);
                      handleCloseOverlay();
                    }}
                    keyElement={'MHVZ'}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <div style={{ width: '100%', textAlign: 'center' }}>
          <h2
            style={{
              ...updatedStyles.subsectionHeading,
              fontWeight: '700',
              textAlign: 'center',
              margin: '2rem auto',
              lineHeight: '22px',
            }}
          >
            Annual Forecasted Median Income Growth
          </h2>
          <div
            className={styles.gridCol2}
            style={{
              position: 'relative',
            }}
          >
            <div className={`${globalStyles.grayBox} ${styles.paddinger}`}>
              <h4>Tract</h4>
              <h3>
                {income?.annual_forecasted_median_household_income_growth_tract ||
                  'N/A'}{' '}
                %
              </h3>
              <HelpOutlineIcon
                onClick={() => {
                  handleQuestionMarkClick('AFMI');
                  setIsOverlayVisible(true);
                }}
                style={updatedStyles.helpoutlineIconStyle}
              />
              {activeOverlay === 'AFMI' && (
                <div
                  style={{
                    position: 'absolute',
                    top: '0%',
                    left: '0%',
                    width: '16rem',
                    zIndex: '1000',
                  }}
                >
                  <Overlay
                    isVisible={isOverlayVisible}
                    onClose={() => {
                      setIsOverlayVisible(false);
                      handleCloseOverlay();
                    }}
                    keyElement={'AFMI'}
                  />
                </div>
              )}
            </div>
            <div
              className={`${globalStyles.grayBox} ${styles.paddinger}`}
              style={{
                position: 'relative',
              }}
            >
              <h4>Zip</h4>
              <h3>
                {income?.annual_forecasted_median_household_income_growth_zip ||
                  'N/A'}{' '}
                %
              </h3>
              <HelpOutlineIcon
                onClick={() => {
                  handleQuestionMarkClick('AFMI2');
                  setIsOverlayVisible(true);
                }}
                style={updatedStyles.helpoutlineIconStyle}
              />
              {activeOverlay === 'AFMI2' && (
                <div
                  style={{
                    position: 'absolute',
                    top: '0%',
                    left: '0%',
                    width: '16rem',
                    zIndex: '1000',
                  }}
                >
                  <Overlay
                    isVisible={isOverlayVisible}
                    onClose={() => {
                      setIsOverlayVisible(false);
                      handleCloseOverlay();
                    }}
                    keyElement={'AFMI2'}
                  />
                </div>
              )}
            </div>
          </div>

          <CustomMarkdown sectionName='income' />
        </div>
      </div>
    </div>
  );
};

export default Income;
