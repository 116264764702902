import React, { useContext, useState } from 'react';
import Markdown from 'markdown-to-jsx';
import VestmapContext from '../../contexts/VestmapContext';
import { Box } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import Overlay from '../Overlay';

function CustomMarkdown({ sectionName }) {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);
  const [activeOverlay, setActiveOverlay] = useState(null);

  const { vestmap } = useContext(VestmapContext);
  if (!sectionName) return null;
  if (!vestmap?.ai_analysis) return null;

  const headingsToRemove = [
    'Abstract',
    'Income',
    'House Price Index',
    'Schools',
    'Crime Index',
    'Population Growth',
    'Transitional Neighborhood Index (TNI)',
  ];

  function isHeadingToRemove(text) {
    const trimmedText = removeColon(text);
    return headingsToRemove.includes(trimmedText);
  }

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  function removeColon(string) {
    if (string.slice(-1) === ':') {
      return string.slice(0, -1);
    }
    return string;
  }

  const iconContainingDiv = {
    display: 'flex',
    alignItems: 'center',
    gap: '0.5rem',
    position: 'relative',
  };

  function handleCloseOverlay() {
    setActiveOverlay(null);
  }

  const handleQuestionMarkClick = key => {
    setActiveOverlay(key);
  };

  const updatedStyles = {
    helpoutlineIconStyle: {
      cursor: 'pointer',
      color: '#4FA490',
    },
  };

  return null;

  return (
    <Box
      sx={{
        paddingTop: '1rem',
        paddingBottom: '1rem',
        paddingLeft: '2rem',
        paddingRight: '2rem',
      }}
    >
      <Box sx={iconContainingDiv}>
        <Header>AI Analysis</Header>
        <HelpOutlineIcon
          onClick={() => {
            handleQuestionMarkClick('Grade');
            setIsOverlayVisible(true);
          }}
          sx={updatedStyles.helpoutlineIconStyle}
        />
        {activeOverlay === 'Grade' && (
          <div
            style={{
              position: 'absolute',
              top: '0%',
              left: '0%',
              width: '16rem',
              zIndex: '1000',
            }}
          >
            <Overlay
              isVisible={isOverlayVisible}
              onClose={() => {
                setIsOverlayVisible(false);
                handleCloseOverlay();
              }}
              keyElement={'Grade'}
            />
          </div>
        )}
      </Box>
      <Markdown
        options={{
          overrides: {
            strong: {
              component: ({ children }) => {
                const text = children[0]; // Assuming single child text node

                if (isHeadingToRemove(text)) {
                  return null; // Remove headings
                }

                return <strong>{children}</strong>; // Render bold text
              },
            },
            p: {
              component: ({ children }) => {
                return <Body>{children}</Body>; // Render paragraph
              },
            },

            br: {
              component: ({ children }) => {
                return null;
              },
            },

            ol: {
              component: ({ children }) => {
                return (
                  <Body>
                    <ol>{children}</ol>
                  </Body>
                );
              },
            },

            ul: {
              component: ({ children }) => {
                return (
                  <Body>
                    <ul>{children}</ul>
                  </Body>
                );
              },
            },
          },
          wrapper: ({ children }) => {
            return <Box>{children}</Box>;
          },
        }}
      >
        {vestmap?.ai_analysis[sectionName]}
      </Markdown>
    </Box>
  );
}

function Header({ children }) {
  const style = {
    fontFamily: 'Lato',
    fontSize: '22.4px',
    fontWeight: '900',
    lineHeight: '20px',
    color: '#39585C',
    textAlign: 'left',
    marginLeft: '0.4rem',
  };

  return <h4 style={style}> {children} </h4>;
}

function Body({ children }) {
  const style = {
    fontFamily: 'Lato',
    fontSize: '16px',
    fontWeight: '500',
    lineHeight: '20px',
    textAlign: 'left',
    // margin: '0',
  };

  return <p style={style}> {children} </p>;
}

export default CustomMarkdown;
