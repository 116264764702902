import { useEffect, useState, createContext, useContext, useRef } from 'react';
import { useNavigation, useSearchParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import Cookies from 'js-cookie';

// Setting up an auth context to be used globally
const AuthContext = createContext({});

export function useAuth() {
  return useContext(AuthContext);
}

// Outseta is added to the window by the Outseta script added to the head in ../public/index.html
export function getOutseta() {
  if (window['Outseta']) {
    return window['Outseta'];
  } else {
    throw new Error('Outseta is missing, have you added the script to head?');
  }
}

export default function AuthProvider({ children }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [status, setStatus] = useState('init');
  const [user, setUser] = useState();
  const navigate = useNavigate();

  // Save a reference to Outseta
  const outsetaRef = useRef(getOutseta());

  useEffect(() => {
    // Get the access token from the callback URL
    const accessToken = searchParams.get('access_token');

    if (accessToken) {
      // If there is an access token present, pass it along to Outseta and clean up
      outsetaRef.current.setAccessToken(accessToken);
      setSearchParams({});
    }

    if (outsetaRef.current.getAccessToken()) {
      // Outseta initialized with an authenticated user
      updateUser();
    } else {
      // Outseta initialized without an authenticated user
      setStatus('ready');
    }

    return () => {
      // Clean up user-related event subscriptions
      handleOutsetaUserEvents(() => {});
    };
  }, [searchParams, setSearchParams]);

  const updateUser = async () => {
    // Fetch the current user data from Outseta
    const outsetaUser = await outsetaRef.current.getUser();
    // Update user state
    setUser(outsetaUser);
    // Make sure status = ready
    setStatus('ready');
  };

  useEffect(() => {
    if (status === 'ready' && user) {
      const access_token = outsetaRef.current.getAccessToken();

      Cookies.set('vestmapToken', access_token, {
        expires: 7,
        secure: true,
        sameSite: 'lax',
      });
    }
  }, [status, user]);

  const handleOutsetaUserEvents = onEvent => {
    // Subscribe to user-related events with onEvent function
    const outseta = outsetaRef.current;
    outseta.on('subscription.update', onEvent);
    outseta.on('profile.update', onEvent);
    outseta.on('account.update', onEvent);
  };

  const getAccessToken = () => {
    if (status === 'ready' && user) {
      return outsetaRef.current.getAccessToken();
    }
  };

  const logout = () => {
    // Unset access token and remove user state
    Cookies.remove('vestmapToken');
    outsetaRef.current.setAccessToken('');
    console.log('logging out');
    localStorage.clear();
    setUser(null);
    navigate('/');
  };

  const openLogin = options => {
    outsetaRef.current.auth.open({
      widgetMode: 'login|register',
      authenticationCallbackUrl: `${window.location.origin}/run-reports`,
      ...options,
    });
  };

  const closeChatHead = options => {
    outsetaRef.current.chat.hide();
  };

  const openSignup = options => {
    outsetaRef.current.auth.open({
      widgetMode: 'register',
      planFamilyUid: 'rmkwe1mg',
      authenticationCallbackUrl: `${window.location.origin}/`,
      ...options,
    });
  };

  const openProfile = options => {
    outsetaRef.current.profile.open({ tab: 'profile', ...options });
  };

  const openChangePlan = () => {
    outsetaRef.current.profile.open({
      tab: 'planChange',
    });
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        isLoading: status !== 'ready',
        logout,
        openLogin,
        openSignup,
        openProfile,
        openChangePlan,
        closeChatHead,
        getAccessToken,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
